<template>
  <h1 class="leading-none lg:mb-8">Cleaning Service</h1>
  <section v-if="booking.country === 'au'">
    <FormField v-slot="{ componentField }" name="is_ndis">
      <FormItem v-auto-animate>
        <FormLabel>
          Is this a disability or aged-care insurance claim for invoicing
          purposes?
        </FormLabel>
        <FormControl>
          <ToggleGroup
            type="single"
            class="radio-buttons mt-12 lg:grid-cols-3 2xl:grid-cols-4 grid-cols-2"
            v-model="booking.is_ndis"
            v-bind="componentField"
          >
            <ToggleGroupItem value="1"> Yes </ToggleGroupItem>
            <ToggleGroupItem value="0"> No </ToggleGroupItem>
          </ToggleGroup>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
  </section>
  <template v-if="booking.is_ndis == '0'">
    <section>
      <FormField v-slot="{ componentField }" name="service">
        <FormItem v-auto-animate>
          <FormLabel as-child>
            <h3>Select your service:</h3>
          </FormLabel>
          <FormControl>
            <ToggleGroup
              v-bind="componentField"
              type="single"
              class="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-4 lg:gap-y-0 gap-y-4 items-end"
              v-model="booking.service"
              @update:model-value="
                (value) => {
                  if (!value) return;
                  let min_hours = booking.getMinimumHours(value);
                  booking.hours = min_hours.toString();
                  props.setFieldValue('hours', min_hours.toString());
                }
              "
            >
              <ToggleGroupItem
                id="regular"
                :value="REGULAR"
                class="service h-full"
                :class="{ selected: booking.service === REGULAR }"
                :disabled="!booking.isRegularEnabled()"
              >
                <div class="flex flex-col justify-between flex-grow gap-y-4">
                  <div
                    class="bg-primary/5 leading-3 dark:bg-primary/30 text-[10px] dark:text-primary -m-8 mb-2 py-2 text-center rounded-t-xl uppercase font-mono tracking-widest"
                  >
                    Most Popular
                  </div>
                  <div>
                    <h2 class="mb-2">Regular Clean</h2>
                    <p class="font-light mb-4 text-sm">
                      Choose from weekly, fortnightly or custom intervals
                    </p>
                  </div>
                  <hr />
                  <div class="rates space-y-2">
                    <p class="font-light text-sm">Starting from</p>
                    <h1>
                      ${{ booking.getBaseRate(REGULAR) }}
                      <span class="text-md"
                        >for {{ booking.getMinimumHours(REGULAR) }} hours</span
                      >
                    </h1>
                    <p class="font-light text-sm">
                      *${{ booking.getHourlyRate(REGULAR) }}/hour thereafter
                    </p>
                  </div>
                </div>
              </ToggleGroupItem>
              <ToggleGroupItem
                id="oneoff"
                :value="ONEOFF"
                class="service h-full -h-12"
                :class="{ selected: booking.service === ONEOFF }"
                :disabled="!booking.isOneoffEnabled()"
              >
                <div class="flex flex-col gap-y-4 flex-grow">
                  <div class="flex-1">
                    <h2 class="mb-2">Once-off Clean</h2>
                    <p class="font-light mb-4 text-sm">
                      Choose from spring clean or end of lease / bond clean
                    </p>
                  </div>
                  <hr class="flex-shrink" />
                  <div class="rates flex-1 space-y-2">
                    <p class="font-light text-sm">Starting from</p>
                    <h1>
                      ${{ booking.getBaseRate(ONEOFF) }}
                      <span class="text-md"
                        >for {{ booking.getMinimumHours(ONEOFF) }} hours</span
                      >
                    </h1>
                    <p class="font-light text-sm">
                      *${{ booking.getHourlyRate(ONEOFF) }}/hour thereafter
                    </p>
                  </div>
                </div>
              </ToggleGroupItem>
            </ToggleGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </section>
    <section v-if="booking.service === REGULAR">
      <FormField v-slot="{ componentField }" name="frequency">
        <FormItem v-auto-animate>
          <FormLabel class="lg:pb-10"> Clean frequency: </FormLabel>
          <FormControl>
            <ToggleGroup
              type="single"
              class="radio-buttons grid-cols-1 xl:grid-cols-3"
              v-model="booking.frequency"
              v-bind="componentField"
              @update:model-value="
                (value) => {
                  if (value === FREQUENCY_FORTNIGHTLY) {
                    props.setFieldValue('days', '1');
                  }
                }
              "
            >
              <ToggleGroupItem
                id="weekly"
                :value="FREQUENCY_WEEKLY"
                class="lg:-mt-8 lg:h-24 h-24 relative"
                :class="{ active: booking.frequency === FREQUENCY_WEEKLY }"
              >
                <div
                  class="absolute top-0 left-0 w-full text-[10px] leading-3 bg-primary/5 rounded-t-lg py-2 uppercase font-mono tracking-widest"
                >
                  popular
                </div>
                <div class="pt-8">Weekly</div>
              </ToggleGroupItem>
              <ToggleGroupItem
                :value="FREQUENCY_FORTNIGHTLY"
                :class="{ active: booking.frequency === FREQUENCY_FORTNIGHTLY }"
              >
                Fortnightly
              </ToggleGroupItem>
            </ToggleGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </section>
    <section v-if="booking.service === ONEOFF">
      <FormField v-slot="{ componentField }" name="oneoff_type">
        <FormItem v-auto-animate>
          <FormLabel> Clean type: </FormLabel>
          <FormControl>
            <ToggleGroup
              type="single"
              class="radio-buttons lg:grid-cols-4 2xl:grid-cols-3 grid-cols-2"
              v-model="booking.oneoff_type"
              v-bind="componentField"
            >
              <ToggleGroupItem
                :value="ONEOFF_TYPE_MOVE_IN"
                :class="{
                  active: booking.oneoff_type === ONEOFF_TYPE_MOVE_IN,
                }"
              >
                Moving In/Out
              </ToggleGroupItem>
              <ToggleGroupItem
                :value="ONEOFF_TYPE_SPRING"
                :class="{
                  active: booking.oneoff_type === ONEOFF_TYPE_SPRING,
                }"
              >
                Spring Clean
              </ToggleGroupItem>
              <ToggleGroupItem
                :value="ONEOFF_TYPE_BOND"
                :class="{
                  active: booking.oneoff_type === ONEOFF_TYPE_BOND,
                }"
              >
                End Of Lease
              </ToggleGroupItem>
            </ToggleGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <div
        class="bg-primary-light p-4 mt-4 rounded border border-primary-soft flex items-center gap-2"
      >
        <AlertCircleIcon class="w-4" />
        No external windows/no carpet cleaning/no pest control
      </div>
    </section>
    <section v-if="booking.frequency === FREQUENCY_WEEKLY">
      <FormField v-slot="{ componentField }" name="days">
        <FormItem v-auto-animate>
          <FormLabel>
            How many days per week should the clean occur?
          </FormLabel>
          <FormControl>
            <ToggleGroup
              type="single"
              class="radio-buttons mt-12 lg:grid-cols-4 2xl:grid-cols-5 grid-cols-2"
              v-model="booking.days"
              v-bind="componentField"
            >
              <ToggleGroupItem
                value="1"
                :class="{ active: booking.days === 1 }"
              >
                1
              </ToggleGroupItem>
              <ToggleGroupItem
                value="2"
                :class="{ active: booking.days === 2 }"
              >
                2
              </ToggleGroupItem>
              <ToggleGroupItem
                value="3"
                :class="{ active: booking.days === 3 }"
              >
                3
              </ToggleGroupItem>
              <ToggleGroupItem
                value="4"
                :class="{ active: booking.days === 4 }"
              >
                4
              </ToggleGroupItem>
              <ToggleGroupItem
                value="5"
                :class="{ active: booking.days === 5 }"
              >
                5
              </ToggleGroupItem>
            </ToggleGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </section>
  </template>
  <Dialog :open="booking.is_ndis == true && !booking.is_ndis_confirmed">
    <DialogContent
      class="lg:p-8 lg:px-16 lg:w-[588px]"
      @close="
        () => {
          booking.is_ndis_confirmed = null;
          booking.is_ndis = null;
          props.setFieldValue('is_ndis', null);
        }
      "
    >
      <DialogHeader class="space-y-4">
        <DialogTitle class="text-center text-[20px] font-medium">
          Are you sure this is a disability or aged-care insurance claim?
        </DialogTitle>
        <DialogDescription class="text-center text-[16px]">
          By confirming, you must ensure that you have your insurance
          information available, we’ll ask you about this later in the booking
          process
        </DialogDescription>
      </DialogHeader>
      <DialogFooter class="mt-4">
        <div class="flex justify-center grow gap-x-2">
          <Button
            size="lg"
            variant="outline"
            @click="
              () => {
                booking.is_ndis_confirmed = null;
                booking.is_ndis = null;
                props.setFieldValue('is_ndis', null);
              }
            "
            class="w-full"
          >
            Cancel
          </Button>
          <Button
            size="lg"
            as="a"
            href="https://book.cleanhomes.com.au"
            target="_blank"
            @click="
              () => {
                booking.is_ndis_confirmed = true;
              }
            "
            class="w-full"
          >
            Continue
          </Button>
        </div>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { useBookingStore } from "~/store";
import { vAutoAnimate } from "@formkit/auto-animate/vue";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  FREQUENCY_WEEKLY,
  FREQUENCY_FORTNIGHTLY,
  ONEOFF,
  REGULAR,
  ONEOFF_TYPE_SPRING,
  ONEOFF_TYPE_BOND,
  ONEOFF_TYPE_MOVE_IN,
} from "~/lib/constants";
import { AlertCircleIcon } from "lucide-vue-next";

const booking = useBookingStore();

const props = defineProps({
  setFieldValue: Function,
});
</script>

<style>
.service {
  @apply text-left justify-start;
  @apply border border-primary/30 rounded-xl p-8 cursor-pointer;
  @apply transition-colors duration-200;
  @apply hover:bg-primary-light;

  &.selected {
    @apply border-primary bg-primary-light;

    &:focus {
      @apply ring-4 ring-primary/10;
    }
  }
}
</style>
