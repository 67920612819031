<script setup lang="ts">
import { useBookingStore } from "~/store/";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/yup";
import * as yup from "yup";
import { toast } from "~/components/ui/toast";
import {
  REGULAR,
  ONEOFF,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_CASH,
  FREQUENCY_WEEKLY,
} from "~/lib/constants";
import * as Sentry from "@sentry/vue";
import { isValidPhoneNumber, type CountryCode } from "libphonenumber-js";

const booking = useBookingStore();
const form = ref<null | any>(null);
const step5 = ref<null | any>(null);

const formSchema = [
  yup.object().shape({
    suburb: yup
      .object()
      .typeError("Please select a suburb")
      .required("Please select a suburb"),
  }),
  yup.object().shape({
    service: yup.string().required("Please select a service"),
    frequency: yup
      .string()
      .nullable()
      .when("$service", {
        is: (service: any) => service === REGULAR,
        then: () => yup.string().required("Please select a frequency"),
      }),
    days: yup
      .string()
      .nullable()
      .when("$frequency", {
        is: (frequency: any) => frequency === FREQUENCY_WEEKLY,
        then: () =>
          yup
            .string()
            .nonNullable()
            .required("Please select the number of days"),
      }),
    is_ndis: yup.number().oneOf([0, 1]).required("Please select an option"),
    oneoff_type: yup
      .string()
      .nullable()
      .when("$service", {
        is: (service: any) => service === ONEOFF,
        then: () =>
          yup.string().nonNullable().required("Please select a one-off type"),
      })
      .nullable(),
  }),
  yup.object().shape({
    hours: yup.string().required("Please select the number of hours"),
    preferred_days: yup
      .array()
      .nullable()
      .when("$service", {
        is: (service: any) => service === REGULAR,
        then: () => {
          return yup
            .array()
            .nonNullable()
            .required("Please select at least one preferred day")
            .min(1, "Please select at least one preferred day");
        },
      }),
    start_date: yup
      .date()
      .nullable()
      .default(null)
      .required("Please select a start date"),
    comments: yup.string().nullable(),
  }),
  yup.object().shape({
    first_name: yup.string().required("Please enter your first name"),
    last_name: yup.string().required("Please enter your last name"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    phone: yup
      .string()
      .required("Please enter your phone number")
      .nullable()
      .test(
        "isValidPhoneNumber",
        "Please enter a valid phone number",
        (value: any) => {
          return isValidPhoneNumber(
            value || "",
            booking.country as CountryCode
          );
        }
      ),
    address: yup.string().required("Please enter your address"),
    address_suburb: yup.string().required("Please enter your suburb"),
    address_state: yup.string().required("Please enter your state"),
    address_postcode: yup.string().required("Please enter your postcode"),
  }),
  yup.object().shape({
    payment_method: yup
      .string()
      .oneOf([PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_CASH])
      .required("Please select a payment method"),
  }),
  yup.object().shape({}),
];

const currentSchema = computed(() =>
  toTypedSchema(formSchema[booking.step - 1], {
    context: {
      ...booking.getValues(),
    },
    abortEarly: false,
  })
);

const { handleSubmit, setFieldValue } = useForm({
  validationSchema: currentSchema,
  initialValues: {
    ...booking.getValues(),
  },
  keepValuesOnUnmount: true,
});

function handleSuburbSelected() {
	doFormSubmission();
}

onMounted(async () => {
  const country = window.location.origin.endsWith("co.nz") ? "nz" : "au";
  booking.setCountry(country);
	setFieldValue("country", country);
	if (country === 'nz') {
		booking.setIsNDIS(0);
		setFieldValue("is_ndis", '0');
	}

  const route = useRoute();

  if (route.query.suburb_id) {
    await useApi()
      .get(`/suburbs/${route.query.suburb_id}`)
      .then((response) => {
        const suburb = response.data.data;
        booking.setSuburb(suburb);
        setFieldValue("suburb", suburb);
        booking.setAddressSuburb(suburb.name);
        setFieldValue("address_suburb", suburb.name);
        booking.setAddressState(suburb.state);
        setFieldValue("address_state", suburb.state);
        booking.setAddressPostcode(suburb.postcode);
        setFieldValue("address_postcode", suburb.postcode);
        doFormSubmission();
      });
  }
});

const handleFormSubmit = (event: Event) => {
	event.preventDefault();

	if (booking.step === 1) {
		return;
	}

	doFormSubmission();
};

const doFormSubmission = handleSubmit(
  async () => {
    if (booking.step === 1) {
      if (!booking.isRegularEnabled() && !booking.isOneoffEnabled()) {
        booking.suburb_not_found = true;
        return;
      }
    }

    if (booking.step === 4) {
      Sentry.setUser({
        name: `${booking.first_name} ${booking.last_name}`,
        email: booking.email!,
        phone: booking.phone,
      });

			if (booking.canUseCreditCard() && booking.suburb?.region?.name === 'Brisbane') {
				booking.payment_method = PAYMENT_METHOD_CREDIT_CARD
				setFieldValue("payment_method", PAYMENT_METHOD_CREDIT_CARD);
			}
    }

    if (booking.step === 5) {
      if (booking.payment_method === PAYMENT_METHOD_CREDIT_CARD) {
        try {
          await step5.value?.beforeSubmit();
        } catch (error) {
          return;
        }
      }

      try {
        await booking.submit();
      } catch (error) {
        Sentry.captureException(error);
        return;
      }
    }

    booking.valid.push(booking.step);
    booking.nextStep();
    nextTick(() => {
      window.scrollTo({
        top: 0,
      });
    });
  },
  () => {
    booking.valid = booking.valid.filter((step) => step !== booking.step);
    const nextError = document.querySelector("h3.invalid");
    if (nextError) {
      nextError.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    toast({
      title: "Please complete all required fields before proceeding",
      variant: "destructive",
    });
  }
);
</script>

<template>
  <div
    class="xl:hidden w-full block mb-4 font-light flex-1"
    v-if="booking.step <= 5"
  >
    Step {{ booking.step }} / 5
  </div>
  <div class="flex justify-between grow gap-x-8 xl:gap-x-16">
    <div class="xl:w-[180px] hidden xl:block">
      <h3 class="lg:mb-4">Book Your Clean</h3>
      <FormSteps />
    </div>
    <div class="xl:max-w-[560px] grow">
      <form id="form" ref="form" @submit.prevent="handleFormSubmit" v-auto-animate>
        <template v-if="booking.step === 1">
          <FormLocation
						@suburb-selected="handleSuburbSelected"
            ref="step-1"
            :setFieldValue="setFieldValue"
            :form="form"
          />
        </template>
        <template v-if="booking.step === 2">
          <FormCleaningService ref="step-2" :setFieldValue="setFieldValue" />
        </template>
        <template v-if="booking.step === 3">
          <FormAboutTheClean ref="step-3" />
        </template>
        <template v-if="booking.step === 4">
          <FormYourDetails ref="step-4" />
        </template>
        <template v-if="booking.step === 5">
          <FormCompleteBooking ref="step5" :setFieldValue="setFieldValue" />
        </template>
        <template v-if="booking.step === 6">
          <FormCompleted />
        </template>
        <FormErrors />
        <FormControls ref="controls" v-if="booking.step <= 5" />
      </form>
    </div>
    <div class="hidden lg:block lg:w-[340px]">
      <FormBookingSummary />
    </div>
  </div>
  <Dialog :open="booking.suburb_not_found">
    <DialogContent
      class="lg:p-8 lg:px-16 lg:w-[588px]"
      @close="
        () => {
          booking.suburb_not_found = false;
        }
      "
    >
      <DialogHeader class="space-y-4">
        <DialogTitle class="text-center text-[20px] font-medium">
          We’re sorry!
        </DialogTitle>
        <DialogDescription class="text-center text-[16px]">
          We don't currently offer any services in your area.
          <br />If you would like to know more, please call our friendly team on
          <a :href="`tel:${booking.getContactNumber()}`" class="text-primary">{{
            booking.getContactNumber()
          }}</a>
        </DialogDescription>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>
